import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - 94px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
        textAlign: 'center',
    },
    icon: {
        fontSize: '5rem',
        color: theme.palette.error.main,
    },
}));

function ErrorPage({title, message, url, urlText}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <ErrorOutlineIcon className={classes.icon} />
                <Typography variant="h4" component="h1">
                  {title}
                </Typography>
                <Typography variant="body1">
                    {message}
                </Typography>
                <NeokodeButton variant="contained" color="primary" href={url || '/'}>
                    {urlText || 'Ir al inicio'}
                </NeokodeButton>
            </Paper>
        </div>
    );
}

export default ErrorPage;