import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import {
  Typography, Grid, Paper, CircularProgress, TextField, Button
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig, getAccessToken } from '../lib/awsLib';
import NeokodeButton from '../components/NeokodeButton';
import InputPassword from '../components/InputPassword';
import * as Icons from '@material-ui/icons';
import { validateName } from '../lib/validate';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  gridMaxWidth: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.md,
    },
  },
  gridMaxWidthSmall: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonChangePassword: {
    margin: theme.spacing(1),
    backgroundColor: '#ff9800',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#ff9800', 0.2),
      color: '#fff',
    },
  },
  buttonEdit: {
    margin: theme.spacing(1),
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#2196f3', 0.2),
      color: '#fff',
    },
  },
  successMessage: {
    margin: '15px 0',
  },
}));

function ProfilePage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [originalUser, setOriginalUser] = useState(null);
  const [user, setUser] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/user/profile`,
      });
      if (response.code === 0) {
        setUser(response.data);
        setOriginalUser({ ...response.data });
      } else {
        setMessage('No pudimos obtener los datos de tu perfil. Intanta nuevamente o comunícate con Soporte');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error al obtener los datos de de tu perfil. Intanta nuevamente o comunícate con Soporte');
      setIsError(true)
    }
    setIsLoading(false);
  }

  const validateEdit = () => {
    let message = undefined;
    if (!validateName(user.name)) {
      message = 'Debes ingresar el nombre correctamente';
    } else {
      if (!validateName(user.lastName)) {
        message = 'Debes ingresar el apellido correctamente';
      }
    }
    return message;
  }

  const handleEditProfile = async (evt) => {
    evt.preventDefault();
    try {
      setIsLoading(true);
      const errorMsg = validateEdit();
      if (errorMsg) {
        setMessage(errorMsg);
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const accessToken = await getAccessToken();
      const request = {
        name: user.name,
        lastName: user.lastName,
        accessToken: accessToken
      }
      const response = await invokeApig({
        path: `/user/profile`,
        method: "PUT",
        body: request
      });
      if (response.code === 0) {
        setIsEdit(false);
        setIsChangePassword(false);
        setIsSuccess(true);
        setMessage('Los datos se han actualizado correctamente');
        props.params.onUpdate && props.params.onUpdate();
      } else {
        setMessage('Ocurrió un error al actualizar tus datos. Intanta nuevamente o comunícate con Soporte');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error al actualizar tus datos. Intanta nuevamente o comunícate con Soporte');
      setIsError(true)
    }
    setIsLoading(false);
  }

  const handleCancelEdit = () => {
    setIsError(false);
    setMessage('');
    setIsChangePassword(false);
    setIsEdit(false);
    setUser(originalUser);
  }

  const handleChangePassword = async (evt) => {
    evt.preventDefault();
    try {
      setIsLoading(true);
      if (!isValidPassword) {
        setMessage('La nueva contraseña no es correcta');
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const accessToken = await getAccessToken();
      const request = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        accessToken: accessToken,
      }
      const response = await invokeApig({
        path: `/user/profile/changePassword`,
        method: "PUT",
        body: request
      });
      if (response.code === 0) {
        setIsEdit(false);
        setIsChangePassword(false);
        setIsSuccess(true);
        setMessage('La contraseña se ha modificado correctamente');
      } else {
        setMessage('Ocurrió un error al actualizar la contraseña. Intanta nuevamente o comunícate con Soporte');
        setIsError(true);
      }
    } catch (error) {
      let message = 'Ocurrió un error al cambiar la contraseña';
      switch (error.code) {
        case 'NotAuthorizedException': {
          message = 'La contraseña actual ingresada es incorrecta';
        }
        case 'InvalidPasswordException': {
          message = 'La nueva contraseña ingresada es incorrecta';
        }
      }
      setMessage(message);
      setIsError(true)
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleShowEditProfile = () => {
    setIsChangePassword(false);
    setIsEdit(true);
  }

  const handleShowChangePassword = () => {
    setOldPassword('');
    setNewPassword('');
    setIsEdit(false);
    setIsChangePassword(true);
  }

  const handleChangePasswordValidate = (isValid) => {
    setIsValidPassword(isValid);
  }

  const renderProfile = () => {
    return (
      isLoading ?
        <CircularProgress />
        :
        (
          isError ?
            <>
              <Alert severity="error">
                {message}
              </Alert>
              <NeokodeButton
                type="button"
                variant="contained"
                className={classes.button}
                component={RouterLink}
                to="/"
              >
                Volver al inicio
              </NeokodeButton>
            </>
            :
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField label="Email" defaultValue={user.email} className={classes.formControl}
                    fullWidth InputProps={{ readOnly: true, }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Estado" defaultValue={user.status} className={classes.formControl}
                    fullWidth InputProps={{ readOnly: true, }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Nombre" defaultValue={user.name} className={classes.formControl}
                    fullWidth InputProps={{ readOnly: true, }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Apellido" defaultValue={user.lastName} className={classes.formControl}
                    fullWidth InputProps={{ readOnly: true, }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    className={classes.buttonEdit}
                    onClick={handleShowEditProfile}
                    startIcon={<Icons.Edit />}
                  >
                    Modificar datos
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    className={classes.buttonChangePassword}
                    onClick={handleShowChangePassword}
                    startIcon={<Icons.Lock />}
                  >
                    Cambiar contraseña
                  </Button>
                </Grid>
              </Grid>
            </>
        )
    );
  }

  const renderEditProfile = () => {
    return (
      <form onSubmit={handleEditProfile}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="Email" defaultValue={user.email} className={classes.formControl} fullWidth InputProps={{ readOnly: true, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Estado" defaultValue={user.status} className={classes.formControl} fullWidth InputProps={{ readOnly: true, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Nombre" name="name" value={user.name} onChange={handleChange} required fullWidth variant="outlined" className={classes.formControl} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Apellido" name="lastName" value={user.lastName} onChange={handleChange} required fullWidth variant="outlined" className={classes.formControl} />
          </Grid>
        </Grid>
        {
          isError &&
          <>
            <Alert severity="error">
              {message}
            </Alert>
          </>
        }
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              type="button"
              variant="contained"
              className={classes.button}
              onClick={handleCancelEdit}
              disabled={isLoading}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <NeokodeButton
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={isLoading}
            >
              {
                isLoading ?
                  <CircularProgress />
                  :
                  'Modificar los datos'
              }
            </NeokodeButton>
          </Grid>
        </Grid>
      </form>
    );
  }

  const renderChangePassword = () => {
    return (
      <>
        <Typography component="h6" variant="h5">
          Cambio de contraseña
        </Typography>
        <form onSubmit={handleChangePassword}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="oldPassword"
            label="Contraseña actual"
            type="password"
            id="oldPassword"
            autoComplete="old-password"
            autoFocus
            value={oldPassword}
            disabled={isLoading}
            onChange={(e) => setOldPassword(e.target.value)}
          />

          <InputPassword
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="Nueva contraseña"
            id="newPassword"
            autoComplete="new-password"
            value={newPassword}
            disabled={isLoading}
            onChange={(e) => setNewPassword(e.target.value)}
            onValidate={handleChangePasswordValidate}
          />
          {
            isError && (
              <Alert severity="error">
                {message}
              </Alert>
            )
          }
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                onClick={handleCancelEdit}
                disabled={isLoading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <NeokodeButton
                type="submit"
                variant="contained"
                className={classes.button}
                disabled={isLoading || !isValidPassword}
              >
                {
                  isLoading ?
                    <CircularProgress />
                    :
                    'Cambiar contraseña'
                }
              </NeokodeButton>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={isChangePassword ? classes.gridMaxWidthSmall : classes.gridMaxWidth}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Tu perfil de usuario
          </Typography>
          {
            isSuccess ?
              <div className={classes.successContainer}>
                <Alert severity="success" icon={<Icons.Check fontSize="inherit" />} className={classes.successMessage}>
                  {message}
                </Alert>
                <NeokodeButton
                  type="button"
                  variant="contained"
                  className={classes.button}
                  component={RouterLink}
                  to="/"
                >
                  Ir al inicio
                </NeokodeButton>
              </div>
              :
              <>
                {
                  isEdit ?
                    renderEditProfile()
                    :
                    (
                      isChangePassword ?
                        renderChangePassword()
                        :
                        renderProfile()
                    )
                }
              </>
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ProfilePage;
