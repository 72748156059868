const local = {
  ga_analytics: '',
  s3: {
    BUCKET: "admin.files.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com"
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "localhost",
        secure: false,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_5VsTpB8bg',
      userPoolWebClientId: 'fdgu6arr80mql74bc1pciq7ga',
      identityPoolId: 'us-east-1:214df7a4-ba0a-4899-850b-42fe1654cf9d'
    }
  },
  apps: {
    admin: 'http://localhost:3001',
    support: 'http://localhost:3000',
    checklist: 'http://localhost:3002',
    fleet: 'http://localhost:3003',
    iot: 'http://localhost:3004',
    web: 'http://localhost:3006',
    files: 'http://localhost:3008',
    billing: 'http://localhost:3007',
    mail: 'http://localhost:3009',
    erp: 'http://localhost:3014',
    domain: 'http://localhost:3011',
    solution: 'http://localhost:3012',
    dashboard: 'http://localhost:3013',
  },
  terms: 'https://www.neokodelabs.com/terms',
  policy: 'https://www.neokodelabs.com/privacy'
};

const dev = {
  ga_analytics: '',
  s3: {
    BUCKET: "admin.files.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com"
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "neokodelabs.com",
        secure: true,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_5VsTpB8bg',
      userPoolWebClientId: 'fdgu6arr80mql74bc1pciq7ga',
      identityPoolId: 'us-east-1:214df7a4-ba0a-4899-850b-42fe1654cf9d'
    }
  },
  apps: {
    admin: 'https://admin.neokodelabs.com',
    support: 'https://support.neokodelabs.com',
    checklist: 'https://checklist.neokodelabs.com',
    fleet: 'https://fleet.neokodelabs.com',
    iot: 'https://iot.neokodelabs.com',
    web: 'https://web.neokodelabs.com',
    files: 'https://files.neokodelabs.com',
    billing: 'https://billing.neokodelabs.com',
    mail: 'https://mail.neokodelabs.com',
    erp: 'https://erp.neokodelabs.com',
    domain: 'https://domain.neokodelabs.com',
    solution: 'https://solution.neokodelabs.com',
    dashboard: 'https://dashboard.neokodelabs.com',
  },
  terms: 'http://neokodelabs.com/terms',
  policy: 'http://neokodelabs.com/policy'
};

const prod = {
  ga_analytics: 'UA-140385946-7',
  s3: {
    BUCKET: "admin.files.neokode.cl"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokode.cl"
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "neokode.cl",
        secure: true,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_eEusLaI2w',
      userPoolWebClientId: '2ek3t58ujs5e79sthtl4gm1rm2',
      identityPoolId: 'us-east-1:937bc5bd-616c-4be1-b522-cc325214693c'
    }
  },
  apps: {
    admin: 'https://admin.neokode.cl',
    support: 'https://support.neokode.cl',
    checklist: 'https://checklist.neokode.cl',
    fleet: 'https://fleet.neokode.cl',
    iot: 'https://iot.neokode.cl',
    web: 'https://web.neokode.cl',
    files: 'https://files.neokode.cl',
    billing: 'https://billing.neokode.cl',
    mail: 'https://mail.neokode.cl',
    erp: 'https://erp.neokode.cl',
    domain: 'https://domain.neokode.cl',
    solution: 'https://solution.neokode.cl',
    dashboard: 'https://dashboard.neokode.cl',
  },
  terms: 'https://www.neokode.cl/terms',
  policy: 'https://www.neokode.cl/policy'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
    ? dev
    : local;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  version: '0.1.2',
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
