import { invokeApig } from './awsLib';

export async function access() {
    try {
        const res = await get_access();
        if (res.code === 0) {
            return res.data.user_access;
        } else {
            return false;
        }
    } catch (ex) {
        return false;
    }
}

function get_access() {
    return invokeApig({
        path: "/user/profile"
    });
};

export function validate_access(access_list, profiles) {
    if (access_list) {
        for (const key in profiles) {
            if (access_list.indexOf(profiles[key]) !== -1) {
                return true;
            }
        }
    }
    return false;
};

export function has_access(profle_acess, app, access_required) {
    const access_list = profle_acess[app];
    if (access_list) {
        for (const index in access_required) {
            const access = access_required[index];
            if (access_list.indexOf(access) !== -1) {
                return true;              
            }
        }
    }
    return false;
};
