import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridOverlay } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
    },
    label: {
        marginTop: theme.spacing(1),
    }
}));

function NoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      
      <div className={classes.label}>Sin datos para mostrar</div>
    </GridOverlay>
  );
}

export default NoRowsOverlay;