import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Paper } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import config from '../config';
import { has_access } from '../lib/access';

const useStyles = makeStyles((theme) => ({
    section: {
        margin: theme.spacing(3, 0),
        marginBottom: theme.spacing(10),
        width: 'inherit',
    },
    paper: {
        padding: theme.spacing(2),
    },
    gridMaxMd: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.lg,
        },
    },
    serviceBox: {
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '15px',
        padding: '15px',
        transition: 'transform 0.5s',
        '&:hover': {
            transform: 'scale(1.1)',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }
    },
    serviceIconContainer: {
        color: '#fff',
        backgroundColor: '#00b31f',
        borderRadius: '60px',
        width: '110px',
        height: '110px',
    },
    serviceIcon: {
        padding: '5px',
        minWidth: 0,
        margin: '15px',
        borderRadius: '5px',
        fontSize: 80,
    },
    serviceLink: {
        textDecoration: 'none'
    },
    servicesTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}));

function ServicePage(props) {
    const {access, isAutenticate} = props.params;
    const classes = useStyles();
    const services = []
    if (has_access(access, 'checklist', ['readonly', 'admin', 'report', 'supervisor'])) services.push({ name: 'Checklist App', desc: 'Controlar tus procesos de forma digital y olvídate de los papeles', icon: Icons.PlaylistAddCheck, url: config.apps.checklist })
    if (has_access(access, 'erp', ['readonly', 'admin'])) services.push({ name: 'ERP App', desc: 'Administra tu negocio con nuestro ERP flexible y sin límites', icon: Icons.BusinessCenter, url: config.apps.erp })
    if (has_access(access, 'mail', ['readonly', 'admin'])) services.push({ name: 'Mail App', desc: 'Administra tus correos corporativos para estar comunicado en todo momento', icon: Icons.Mail, url: config.apps.mail })
    if (has_access(access, 'domain', ['readonly', 'admin'])) services.push({ name: 'Domain App', desc: 'Administra tus dominios de la manera más simple', icon: Icons.Language, url: config.apps.domain })
    if (has_access(access, 'web', ['readonly', 'admin'])) services.push({ name: 'Web App', desc: 'Administra tus sitios web corporativos y haz visible tu negocio', icon: Icons.Web, url: config.apps.web })
    if (has_access(access, 'solution', ['readonly', 'admin'])) services.push({ name: 'Solution App', desc: 'Soluciona las fallas de tus activos de forma controlada', icon: Icons.Build, url: config.apps.solution })
    if (has_access(access, 'files', ['readonly', 'admin'])) services.push({ name: 'Files App', desc: 'Visualiza tus archivos de forma rápida, simple y sin límites', icon: Icons.FileCopy, url: config.apps.files })
    if (has_access(access, 'dashboard', ['readonly', 'admin'])) services.push({ name: 'Dashboard App', desc: 'Monitorea tu información en un tablero interactivo', icon: Icons.Speed, url: config.apps.dashboard})
    if (has_access(access, 'fleet', ['readonly', 'admin'])) services.push({ name: 'Fleet App', desc: 'Administra tu flota de vehículos con enfoque en la seguridad y ecología', icon: Icons.DirectionsBus, url: config.apps.fleet})
    if (has_access(access, 'iot', ['readonly', 'admin'])) services.push({ name: 'IoT App', desc: 'Monitorea y controla objetos obteniendo información de manera inteligente', icon: Icons.AccountTree, url: config.apps.iot})
        
    const goToUrl = (url) => {
        window.location.href = url;
    }

    return (
        <Grid container spacing={2} className={classes.section} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Paper className={classes.paper}>
                    <Typography variant="h3" className={classes.servicesTitle}>Servicios disponibles</Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        {
                            (services && services.length > 0)
                            ?
                            services.map((service, index) => (
                                <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" onClick={() => goToUrl(service.url)} className={classes.serviceBox}>
                                        <div className={classes.serviceIconContainer}>
                                            <service.icon className={classes.serviceIcon}/>
                                        </div>
                                        <Typography variant="h6">{service.name}</Typography>
                                        <Typography variant="body2">{service.desc}</Typography>
                                    </Box>
                                </Grid>
                            ))
                            :
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.serviceBox}>
                                    <Typography variant="h6">No tiene servicios disponibles</Typography>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ServicePage;