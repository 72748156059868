export default {
  user: {
    name: 'Usuarios',
    list: [
      { value: 'admin', label: 'Administrador' },
      { value: 'readonly', label: 'Usuario' }
    ]
  },
  checklist: {
    name: 'Checklist',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'report', label: 'Reportes' },
      { value: 'supervisor', label: 'Supervisor' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
  domain: {
    name: 'Domain',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
/*  web: {
    name: 'Web',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },*/
  mail: {
    name: 'Mail',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
/*  files: {
    name: 'Files',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },*/
  erp: {
    name: 'ERP',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
  solution: {
    name: 'Solution',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
  dashboard: {
    name: 'Dashboard',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
/*  fleet: {
    name: 'Fleet',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  },
  iot: {
    name: 'IoT',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  }, */
  billing: {
    name: 'Billing',
    list: [
      { value: 'readonly', label: 'Usuario' },
      { value: 'admin', label: 'Administrador' }
    ]
  }
}