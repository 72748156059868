import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Footer from './Footer';
import HomePage from '../pages/HomePage';
import ServicePage from '../pages/ServicePage';
import UsersPage from '../pages/UsersPage';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../pages/NotFoundPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import { invokeApig } from '../lib/awsLib';
import UserPage from '../pages/UserPage';
import ProfilePage from '../pages/ProfilePage';
import Alert from '@material-ui/lab/Alert';
import { Container, Grid } from '@material-ui/core';
import config from '../config';
import ErrorPage from '../pages/ErrorPage';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        '& > div:nth-of-type(2)': {
            minHeight: 'calc(100% - 198px)',
        }
    },
    loadingContainer: {
        textAlign: 'center',
        marginTop: '60px',
    }
}));

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                rest.isAuthenticated ? (
                    <Component {...props} params={rest}/>
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

const Layout = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingBill, setIsLoadingBill] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isError, setIsError] = useState(false);
    const [redirectToBilling, setRedirectToBilling] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState('');

    useEffect(() => {
        checkAuthState();
    }, []);

    async function getAccess() {
        try {
            const response = await invokeApig({
                path: '/user/profile'
            });
            if (response.code === 0) {
                setAccess(response.data.user_access);
                setCompany(response.data.company);
            }
        } catch (error) {
            console.error('Error in getAccess:', error);
        }
    }

    const checkBilling = async () => {
        try {
            const response = await invokeApig({
                app: 'billing',
                path: '/billing/validation'
            });
            if (response.code === 0) {
                if (!response.profile || (response.autoPay && !response.payment)) {
                    setRedirectToBilling(true);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsError(true);
            }
        } catch (error) {
            console.error('Error in getAccess:', error);
            setIsError(true);
        }
    }

    const checkAuthState = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            setCurrentUser(user);
            setIsAuthenticated(true);
            await getAccess();
            setIsLoadingBill(true);
            checkBilling();
        } catch (err) {
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    };

    const handleUpdateProfile = () => {
        checkAuthState();
    }

    if (redirectToBilling) {
        window.location.href = config.apps.billing;
        return <>Redirigiendo a Billing App...</>;
    }

    if (isError) {
        return (
            <ErrorPage title='Error de validación' message='Ocurrió un error al validar tu perfil. Esto no es algo esperado por lo que puedes intentar nuevamente y si el problema persiste por favor contacta a Soporte.' />
        );
    }

    return (
        isLoading ?
        <Container maxWidth="sm">
            <Grid container spacing={3} className={classes.loadingContainer}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity='info'>
                        { isLoadingBill ? 'Validando perfil...' : 'Validando acceso...' }
                    </Alert>
                </Grid>
            </Grid>
        </Container>
        :
        (
            isAuthenticated ?
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <Header user={currentUser} isAuthenticated={isAuthenticated} access={access} company={company} />
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Switch>
                            <PrivateRoute exact path="/" component={HomePage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/services" component={ServicePage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/users" component={UsersPage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/users/create" component={UserPage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/users/edit/:id" component={UserPage} isAuthenticated={isAuthenticated} access={access} currentUser={currentUser} />
                            <PrivateRoute exact path="/profile" component={ProfilePage} isAuthenticated={isAuthenticated} access={access} onUpdate={handleUpdateProfile} />
                            <Route path="/login" component={LoginPage} />
                            <Route component={NotFoundPage} />
                        </Switch>
                        <Footer />
                    </main>
                </div>      
            </Router>
            :
            <Router>
                <Switch>
                    <Route path="/" component={LoginPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </Router>
        )
    );
};

export default Layout;
