import React, { useEffect } from 'react';
import Layout from './layout/Layout';
import * as serviceWorker from './serviceWorker';

const App = () => {
    useEffect(() => {
        serviceWorker.register({
            onUpdate: registration => {
                const waitingServiceWorker = registration.waiting;
                if (waitingServiceWorker) {
                    waitingServiceWorker.addEventListener('statechange', event => {
                        if (event.target.state === 'activated') {
                            window.location.reload();
                        }
                    });
                    waitingServiceWorker.postMessage({ action: 'skipWaiting' });
                }
            },
        });
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Layout />
        </div>
    );
};

export default App;
