import { withStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';

const NeokodeButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: '#00b31f',
      '&:hover': {
        color: '#fff',
        backgroundColor: darken('#00b31f', 0.2),
      },
    },
  }))(Button);

  export default NeokodeButton;