import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    progressRoot: {
        height: 10,
        borderRadius: 5,    
    },
    progressColorPrimary: {
        backgroundColor: '#eeeeee',
    },
    progressBarColorPrimary: {
        backgroundColor: '#4caf50',
    },
    label: {
        textAlign: 'center',
        marginTop: 5,
        visibility: 'hidden',
    },
    checkIcon: {
        color: green[500],
    },
    errorIcon: {
        color: red[500],
    },
    boxHelp: {
        marginTop: '15px',
    },
    boxHelpProgress: {
        textAlign: 'center',
    }
});

const InputPassword = (props) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isValidStrength, setIsValidStrength] = useState(false);
    const [validations, setValidations] = useState([]);

    const { onValidate, ...propsP } = props;

    useEffect(() => {
        const vals = [];
        if (props.may === undefined || props.may) vals.push({ label: 'Mayúscula', regex: /[A-Z]/ });
        if (props.min === undefined || props.min) vals.push({ label: 'Minúscula', regex: /[a-z]/ });
        if (props.num === undefined || props.num) vals.push({ label: 'Número', regex: /[0-9]/ });
        if (props.sym === undefined || props.sym) vals.push({ label: 'Símbolo', regex: /[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/ });

        const calculateStrength = (password) => {
            let strength = 0;
            vals.forEach((validation) => {
                if (validation.regex.test(password)) {
                    strength++;
                    validation.valid = true;
                } else {
                    validation.valid = false;
                }
            });
            return strength;
        };

        const validLength = props.value.length >= 8;
        const strength = calculateStrength(props.value) + (validLength ? 1 : 0);
        vals.push({ label: 'Largo', valid: validLength });
        const isValid = strength >= vals.length;
        setIsValidStrength(isValid);
        setValidations(vals);
        if (typeof onValidate === 'function') {
            onValidate(isValid);
        }
    }, [props.value]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={classes.root}>
            <TextField
                {...propsP}
                type={showPassword ? 'text' : 'password'}
                onFocus={handleFocus}
                onBlur={handleBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            {props.value.length === 0 ? null : isValidStrength ? (
                                <CheckCircleIcon className={classes.checkIcon} />
                            ) : (
                                <ErrorIcon className={classes.errorIcon} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {isFocused && (
                <>
                    <Box display="flex" alignItems="center" className={classes.boxHelp}>
                        {validations.map((validation, index) => (
                            <Box key={index} width="25%" mr={index < validations.length - 1 ? 0.5 : 0} className={classes.boxHelpProgress}>
                                <LinearProgress
                                    variant="determinate"
                                    value={validation.valid ? 100 : 0}
                                    classes={{
                                        root: classes.progressRoot,
                                        colorPrimary: classes.progressColorPrimary,
                                        barColorPrimary: classes.progressBarColorPrimary,
                                    }}
                                    style={{ visibility: 'visible' }}
                                />
                                <Typography variant="caption" className={classes.label} style={{ visibility: 'visible' }}>
                                    {validation.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Typography variant="body2" color="textSecondary" align="center" className={classes.label} style={{ visibility: 'visible' }}>
                        La contraseña debe tener mayúscula, minúscula, número, símbolo y ser de al menos 8 caracteres
                    </Typography>
                </>
            )}
        </div>
    );
};

export default InputPassword;