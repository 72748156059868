import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, makeStyles, Container, CssBaseline, Avatar, Typography, CircularProgress, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LogoNK from '../assets/images/logo-nk.png';
import NeokodeButton from '../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import * as Icons from '@material-ui/icons';
import InputPassword from '../components/InputPassword';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonTermsContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    errorMessage: {
        margin: '30px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        height: theme.spacing(20),
        width: theme.spacing(20),
    },
    logo: {
        height: '100%',
        width: 'auto'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    successContainer: {
        margin: '15px 0',
    },
    successMessage: {
        margin: '15px 0',
    },
}));

function ForgotPasswordForm(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [username, setUsername] = useState(props.username);
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);;
    const [isVerificationCode, setIsVerificationCode] = useState(props.isVerificationCode === true);
    
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setIsError(false);
            await Auth.forgotPassword(username);
            setIsVerificationCode(true);
        } catch (error) {
            let message = 'Ocurrió un al obtener el código de verificación. Intenta nuevamente o comunícate con Soporte a soporte@neokode.cl';
            setErrorMessage(message);
            setIsError(true);
        }
        setIsLoading(false);
    }
    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setIsError(false);
            const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
            console.log('Contraseña cambiada correctamente', data);
            setSuccessMessage('La contraseña se ha cambiado exitosamente');
            setIsSuccess(true);
        } catch (error) {
            let message = 'Ocurrió un error al cambiar la contraseña';
            switch (error.code) {
                case 'UserNotFoundException': {
                    message = 'El usuario ingresado no existe';
                    break;
                }
                case 'InvalidPasswordException': {
                    message = 'La nueva contraseña ingresada es incorrecta';
                    break;
                }
                case 'CodeMismatchException': {
                    message = 'El código de verificación no es correcto';
                    break;
                }
                case 'ExpiredCodeException': {
                    message = 'El código de verificación expiró por lo que debes obtener otro';
                    break;
                }
                case 'LimitExceededException': {
                    message = 'Superaste el límite de intentos, esperar un tiempo e inténtalo nuevamente';
                    break;
                }
            }
            setErrorMessage(message);
            setIsError(true);
        }
        setIsLoading(false);
    };

    const handleChangePasswordValidate = (isValid) => {
        setIsValidPassword(isValid);
    }

    const handleCloseError = () => {
        setIsError(false);
        setErrorMessage('');
    };

    const goToHome = () => {
        window.location.href = '/'
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <img src={LogoNK} alt="Logo" className={classes.logo} />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Recuperación de contraseña
                </Typography>
                {
                    isSuccess ?
                    <div className={classes.successContainer}>
                        <Alert severity="success" icon={<Icons.Check fontSize="inherit" />} className={classes.successMessage}>
                            { successMessage }
                        </Alert>
                        <NeokodeButton
                            variant="contained"
                            fullWidth
                            onClick={goToHome}
                            >
                            Ingresar
                        </NeokodeButton>
                    </div>
                    :
                    (
                        isVerificationCode ?
                        <form className={classes.form} onSubmit={handleResetPassword} autoComplete='off'>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Usuario"
                                name="username"
                                autoComplete="new-password"
                                value={username}
                                disabled={isLoading}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="code"
                                label="Código de verificación"
                                name="code"
                                autoComplete="new-password"
                                value={code}
                                disabled={isLoading}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <InputPassword
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="newPassword"
                                label="Nueva contraseña"
                                id="newPassword"
                                autoComplete="new-password"
                                autoFocus
                                value={newPassword}
                                disabled={isLoading}
                                onChange={(e) => setNewPassword(e.target.value)}
                                onValidate={handleChangePasswordValidate}
                            />
                            {
                                isError && (
                                    <Alert severity="error" onClose={handleCloseError}>
                                        {errorMessage}
                                    </Alert>
                                )
                            }
                            <NeokodeButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={isLoading || !isValidPassword}
                            >
                                {
                                    isLoading ?
                                    <CircularProgress />
                                    :
                                    'Cambiar contraseña'
                                }
                            </NeokodeButton>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={goToHome}
                                disabled={isLoading}
                            >
                                Volver
                            </Button>
                        </form>
                        :
                        <form className={classes.form} onSubmit={handleForgotPassword}>
                            <Alert severity="info">
                                Para recuperar tu contraseña te enviaremos un código que deberás ingresar en el siguiente paso
                            </Alert>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Usuario"
                                name="username"
                                autoComplete="username"
                                value={username}
                                disabled={isLoading}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {
                                isError && (
                                    <Alert severity="error" onClose={handleCloseError}>
                                        {errorMessage}
                                    </Alert>
                                )
                            }
                            <NeokodeButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={isLoading}
                            >
                                {
                                    isLoading ?
                                    <CircularProgress />
                                    :
                                    'Enviar código'
                                }
                            </NeokodeButton>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={goToHome}
                                disabled={isLoading}
                            >
                                Volver
                            </Button>
                        </form>
                    )
                }
            </div>
        </Container>
    );
}

export default ForgotPasswordForm;