import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, makeStyles, Container, CssBaseline, Avatar, Typography, CircularProgress, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LogoNK from '../assets/images/logo-nk.png';
import NeokodeButton from '../components/NeokodeButton';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import * as Icons from '@material-ui/icons';
import InputPassword from '../components/InputPassword';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    terms: {
        maxHeight: `calc(100vh - 300px)`,
        overflow: 'auto',
        textAlign: 'justify',
        padding: '30px',
    },
    buttonTermsContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    errorMessage: {
        margin: '30px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        height: theme.spacing(20),
        width: theme.spacing(20),
    },
    logo: {
        height: '100%',
        width: 'auto'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    successContainer: {
        margin: '15px 0',
    },
    successMessage: {
        margin: '15px 0',
    },
}));

function ChangePasswordForm(props) {
    const handleActiveButtonTerms = () => {
        setActiveButtonTerms(true);
    }
    
    const classes = useStyles();
    const scrollRef = useBottomScrollListener(handleActiveButtonTerms);
    const [isLoadingTerms, setIsLoadingTerms] = useState(true);
    const [termsHtml, setTermsHtml] = useState('');
    const [activeButtonTerms, setActiveButtonTerms] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [rejectTerms, setRejectTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [username, setUsername] = useState(props.username);
    const [oldPassword, setOldPassword] = useState(props.password);
    const [newPassword, setNewPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);

    useEffect(() => {
        loadTerms();
    }, []);

    const loadTerms = async () => {
        fetch('terms.html')
            .then(response => {
                if (!response.ok) {
                    alert('error1')
                }
                return response.text();
            })
            .then(htmlText => {
                setTermsHtml(htmlText);
                setIsLoadingTerms(false);
            })
            .catch(error => {
                alert('error2')
            });
    };

    const handleAcceptTerms = () => {
        setAcceptTerms(true);
    }

    const handleRejectTerms = () => {
        setRejectTerms(true);
    }

    const handleShowTerms = () => {
        setRejectTerms(false);
    }

    const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setIsError(false);
            const user = await Auth.signIn(username, oldPassword);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await Auth.completeNewPassword(user, newPassword);
                setSuccessMessage('La contraseña se ha cambiado exitosamente');
                setIsSuccess(true);
            } else {
                setErrorMessage('El usuario no tiene pendiente un cambio de contraseña');
                setIsError(true);
            }
        } catch (error) {
            let message = 'Ocurrió un error al cambiar la contraseña';
            switch (error.code) {
                case 'UserNotFoundException': {
                    message = 'El usuario ingresado no existe';
                    break;
                }
                case 'NotAuthorizedException': {
                    message = 'La contraseña actual ingresada es incorrecta';
                }
                case 'InvalidPasswordException': {
                    message = 'La nueva contraseña ingresada es incorrecta';
                }
            }
            setErrorMessage(message);
            setIsError(true);
        }
        setIsLoading(false);
    };

    const handleChangePasswordValidate = (isValid) => {
        setIsValidPassword(isValid);
    }

    const handleCloseError = () => {
        setIsError(false);
        setErrorMessage('');
    };

    const goToHome = () => {
        window.location.href = '/'
    }

    const renderTerms = () => {
        return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <img src={LogoNK} alt="Logo" className={classes.logo} />
                    </Avatar>
                    {
                        rejectTerms ?
                        <div className={classes.rejectTermsContainer}>
                            <Alert severity="error" icon={<Icons.Error fontSize="inherit" />} className={classes.errorMessage}>
                                Debes aceptar los términos y condiciones para poder utilizar nuestra plataforma<br/>
                            </Alert>
                            <div className={classes.buttonTermsContainer}>
                                <NeokodeButton
                                    variant="contained"
                                    onClick={handleShowTerms}
                                    >
                                    Volver
                                </NeokodeButton>
                            </div>
                        </div>
                        :
                        <>
                            <div ref={scrollRef} className={classes.terms} dangerouslySetInnerHTML={{__html: termsHtml}}></div>
                            <div className={classes.buttonTermsContainer}>
                                <NeokodeButton
                                    variant="contained"
                                    onClick={handleAcceptTerms}
                                    disabled={!activeButtonTerms}
                                    >
                                    Acepto
                                </NeokodeButton>
                                <Button
                                    variant="contained"
                                    onClick={handleRejectTerms}
                                    disabled={!activeButtonTerms}
                                    >
                                    No acepto
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </Container>
        );
    };

    const renderChangePassword = () => {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <img src={LogoNK} alt="Logo" className={classes.logo} />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Cambio de contraseña
                    </Typography>
                    {
                        isSuccess ?
                        <div className={classes.successContainer}>
                            <Alert severity="success" icon={<Icons.Check fontSize="inherit" />} className={classes.successMessage}>
                                { successMessage }
                            </Alert>
                            <NeokodeButton
                                variant="contained"
                                fullWidth
                                onClick={goToHome}
                                >
                                Ingresar
                            </NeokodeButton>
                        </div>
                        :
                        <form className={classes.form} onSubmit={handleChangePassword}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Usuario"
                                name="username"
                                autoComplete="username"
                                value={username}
                                disabled={isLoading}
                                InputProps={{
                                    readOnly: true,
                                }}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="oldPassword"
                                label="Contraseña actual"
                                type="password"
                                id="oldPassword"
                                autoComplete="old-password"
                                value={oldPassword}
                                disabled={isLoading}
                                InputProps={{
                                    readOnly: true,
                                }}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />

                            <InputPassword
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="newPassword"
                                label="Nueva contraseña"
                                id="newPassword"
                                autoComplete="new-password"
                                autoFocus
                                value={newPassword}
                                disabled={isLoading}
                                onChange={(e) => setNewPassword(e.target.value)}
                                onValidate={handleChangePasswordValidate}
                            />
                            {
                                isError && (
                                    <Alert severity="error" onClose={handleCloseError}>
                                        {errorMessage}
                                    </Alert>
                                )
                            }
                            <NeokodeButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={isLoading || !isValidPassword}
                            >
                                {
                                    isLoading ?
                                    <CircularProgress />
                                    :
                                    'Cambiar contraseña'
                                }
                            </NeokodeButton>
                        </form>
                    }
                </div>
            </Container>
        );
    }

    return (
        isLoadingTerms ?
        <CircularProgress />
        :
        (
            acceptTerms ?
            renderChangePassword()
            :
            renderTerms()
        )
    );
}

export default ChangePasswordForm;